import { getSettingValue } from '@shared/helpers/settings';
import { CONFIG } from '@shared/environment/environment';
import { INSURER_CONFIG } from '@shared/insurer';
export default (function (context, inject) {
    async function goBackHome(saveStep, customerAreaUrlParam) {
        var _a;
        const store = context.store;
        const { nodeEnv } = CONFIG;
        let settingName = 'customerAreaUrl';
        if (saveStep) {
            const activeStep = typeof saveStep === 'string' ? saveStep : store.state.pages.activeStep;
            if (activeStep)
                await store.dispatch('claim/updateAndSaveClaimStep', activeStep);
        }
        if (store.getters['authentication/isAuthenticatedWithMagicLink']) {
            store.commit('authentication/SET_MAGIC_LINK', null);
            if ((_a = INSURER_CONFIG.declaration.closeCallBackBtnBehavior) === null || _a === void 0 ? void 0 : _a.specificTargetIfMagicLink) {
                if (nodeEnv === 'development') {
                    settingName = 'devCustomerAreaUrl';
                }
            }
        }
        if (store.state.settings.appSettings &&
            store.state.settings.appSettings.some((appSetting) => appSetting.name === settingName) &&
            getSettingValue(settingName, {
                settings: store.state.settings.appSettings,
            }) !== null) {
            window.location.href =
                getSettingValue(settingName, {
                    settings: store.state.settings.appSettings,
                }) + (customerAreaUrlParam ? '/' + customerAreaUrlParam : '');
        }
        else {
            context.$goToLocalePath('index');
        }
    }
    inject('goBackHome', goBackHome);
});
